import { onValue, ref } from "firebase/database";
import { createContext, useEffect, useReducer, useState } from "react";
import { db } from "../firebase";
import RestaurantReducer from "./RestaurantReducer";

const INITIAL_STATE = {
  currentUser: JSON.parse(localStorage.getItem("user")) || null,
};

export const RestaurantContext = createContext(INITIAL_STATE);

export const RestaurantContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(RestaurantReducer, INITIAL_STATE);
  const [restaurant, setRestaurant] = useState(null);
  const [dish, setDish] = useState("");
  const [orderId, setOrderId] = useState("");
  const [orderZone, setOrderZone] = useState("");
  const [userId, setUserId] = useState(null);

  //let userId = state?.currentUser?.uid;

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(state.currentUser));
  }, [state.currentUser]);

  // get Restaurants  from firebase
  const ordersRef = ref(db, "restaurants");
  useEffect(() => {
    if (!userId) {
      return;
    }

    onValue(ordersRef, (snapshot) => {
      const data = snapshot.exportVal();
      const restaurants = Object.entries(data);

      // filter restaurant by userId
      const currentRest = restaurants.filter((rest) => {
        return rest[1].restaurantProfile.userId === userId;
      });

      setRestaurant(currentRest[0]);
    });
  }, [userId]);

  return (
    <RestaurantContext.Provider
      value={{
        currentUser: state.currentUser,
        dispatch,
        restaurant,
        dish,
        setDish,
        orderId,
        setOrderId,
        setOrderZone,
        orderZone,
      }}
    >
      {children}
    </RestaurantContext.Provider>
  );
};
