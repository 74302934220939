import React, { useContext, useState } from "react";

import { Form, Input, Card, Button, message, TimePicker } from "antd";
import moment from "moment";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import { ref, set, update } from "firebase/database";
import { db } from "../../firebase";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import { RestaurantContext } from "../../Contexts/RestaurantContext";
import TextArea from "antd/lib/input/TextArea";

const Settings = () => {
  const { currentUser, restaurant } = useContext(RestaurantContext);

  const [restName, setRestName] = useState("");
  const [address, setAddress] = useState(null);
  const [coordinates, setCoordinates] = useState(null);
  const [newRestaurant, setNewRestaurant] = useState("");
  const [deliveryFees, setDeliveryFees] = useState("");
  const [minDeliveryTime, setminDeliveryTime] = useState("");
  const [maxDeliveryTime, setmaxDeliveryTime] = useState("");
  const [userName, setUserName] = useState("");
  const [restImage, setRestImage] = useState("");
  const [openTime, setOpenTime] = useState("");
  const [closeTime, setCloseTime] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);

  let restaurantId = restaurant ? restaurant[1]?.restaurantProfile?.Id : "";

  // extract and set the opening time
  const onChange = (date, dateString) => {
    setOpenTime(dateString);
  };

  // extract and set the closing time
  const onChangeClose = (date, dateString) => {
    setCloseTime(dateString);
  };

  const navigate = useNavigate();

  const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

  const getAddressLatLng = async (address) => {
    setAddress(address);
    const geocodedByAddress = await geocodeByAddress(address.label);
    const latLng = await getLatLng(geocodedByAddress[0]);
    setCoordinates(latLng);
  };

  // create new / update restaurant profile
  const onFinish = ({
    name,
    categories,
    description,
    deliveryFee,
    minDeliveryTime,
    maxDeliveryTime,
    user,
    image,
  }) => {
    let restaurantProfile = {
      Id: uuidv4(),
      userId: currentUser.uid,
      name,
      category: categories.split(","),
      description,
      deliveryFee,
      minDeliveryTime,
      maxDeliveryTime,
      user,
      image,
      openingHour: openTime,
      closingHour: closeTime,
      lat: coordinates.lat,
      lng: coordinates.lng,
      rating: 4.5,
      reviews: 210,
    };

    if (restaurant) {
      // add condition if restaurant exist update else add new
      update(ref(db, `/restaurants/${restaurantId}/restaurantProfile`), {
        Id: restaurantId,
        name,
        category: categories.split(","),
        description,
        deliveryFee,
        minDeliveryTime,
        maxDeliveryTime,
        user,
        image,
        openingHour: openTime,
        closingHour: closeTime,
        lat: coordinates.lat,
        lng: coordinates.lng,
        rating: 4.5,
        reviews: 210,
      }).catch((err) => {
        console.log("err", err);
      });
      message.success("Restaurant has been updated");
      navigate("/");
    } else {
      // console.log(restaurantProfile);
      set(ref(db, `/restaurants/${restaurantProfile.Id}`), {
        restaurantProfile,
        createdAt: new Date().toString(),
      }).catch((err) => {
        console.log("err", err);
      });
      setNewRestaurant(restaurantProfile);
      message.success("Restaurant has been created !");
      navigate("/");
    }
  };

  const initialValues = restaurant
    ? {
        name: restaurant[1].restaurantProfile.name,
        categories: restaurant[1].restaurantProfile.category
          ? Object.values(restaurant[1].restaurantProfile.category).join(" ")
          : "",
        description: restaurant[1].restaurantProfile.description,
        deliveryFee: restaurant[1].restaurantProfile.deliveryFee,
        minDeliveryTime: restaurant[1].restaurantProfile.minDeliveryTime,
        maxDeliveryTime: restaurant[1].restaurantProfile.maxDeliveryTime,
        user: restaurant[1].restaurantProfile.user,
        image: restaurant[1].restaurantProfile.image,
      }
    : {};

  return (
    <Card title="Restaurant Details" style={{ margin: 20 }}>
      <Form
        layout="vertical"
        wrapperCol={{ span: 8 }}
        onFinish={onFinish}
        initialValues={initialValues}
      >
        <Form.Item
          label="Restaurant Name"
          name="name"
          rules={[
            {
              required: true,
              message: "restaurant name required !",
            },
          ]}
        >
          <Input placeholder="Enter restaurant name here" />
        </Form.Item>

        <Form.Item
          rules={[
            {
              required: true,
              message: "restaurant category is required !",
            },
          ]}
          label="Restaurant Category"
          name="categories"
          required
        >
          <Input placeholder="Enter restaurant Category here bar, cafe" />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: "restaurant description is required !",
            },
          ]}
          label="Restaurant Description"
          name="description"
          required
        >
          <TextArea rows={4} placeholder="Enter restaurant description" />
        </Form.Item>

        <Form.Item
          rules={[
            {
              required: true,
              message: "restaurant Delivery fees is required Kanjar!",
            },
          ]}
          label="Delivery fees "
          name="deliveryFee"
          required
        >
          <Input placeholder="Enter delivery fees" />
        </Form.Item>

        <Form.Item
          rules={[
            {
              required: true,
              message: "this field is required !",
            },
          ]}
          label="Min delivery time in min "
          name="minDeliveryTime"
          required
        >
          <Input placeholder="Enter min delivery time" />
        </Form.Item>

        <Form.Item
          rules={[
            {
              required: true,
              message: "this field is required !",
            },
          ]}
          label="Max Delivery time in min"
          name="maxDeliveryTime"
          required
        >
          <Input placeholder="Enter Max Delivery time" />
        </Form.Item>

        <Form.Item
          rules={[
            {
              required: true,
              message: "this field is required !",
            },
          ]}
          label="User's name"
          name="user"
          required
        >
          <Input placeholder="Enter your user's name here" />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: "this field is required !",
            },
          ]}
          label="Restaurant Image"
          name="image"
          required
        >
          <Input placeholder="Enter your restaurant image link here" />
        </Form.Item>

        <Form.Item
          rules={[
            {
              required: true,
              message: "this field is required !",
            },
          ]}
          label="Open At"
          name="openAt"
        >
          <TimePicker
            defaultValue={moment(
              restaurant
                ? restaurant[1].restaurantProfile.openingHour
                : "08:00:00",
              "HH:mm:ss"
            )}
            size="large"
            onChange={onChange}
          />
        </Form.Item>

        <Form.Item
          rules={[
            {
              required: true,
              message: "this field is required !",
            },
          ]}
          label="Close At"
          name="closeAt"
        >
          <TimePicker
            defaultValue={moment(
              restaurant
                ? restaurant[1].restaurantProfile.closingHour
                : "23:00:00",
              "HH:mm:ss"
            )}
            size="large"
            onChange={onChangeClose}
          />
        </Form.Item>

        <Form.Item label="Restaurant Address">
          <GooglePlacesAutocomplete
            apiKey={API_KEY}
            selectProps={{
              value: address,
              onChange: getAddressLatLng,
            }}
          />
          <span style={{ color: "red" }}>
            {!coordinates?.lat && "this field is required !"}
          </span>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {restaurant ? "Update" : "Submit"}
          </Button>
        </Form.Item>
      </Form>
      <span>
        {restaurant ? restaurant[1].restaurantProfile.lat : coordinates?.lat}
        {restaurant ? restaurant[1].restaurantProfile.lng : coordinates?.lng}
      </span>
    </Card>
  );
};

export default Settings;
