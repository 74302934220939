import { Form, Input, Button, Card, InputNumber, message } from "antd";
import { ref, set } from "firebase/database";
import { useContext, useState } from "react";
import { RestaurantContext } from "../../Contexts/RestaurantContext";
import { db } from "../../firebase";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";

const { TextArea } = Input;

const CreateMenuItem = () => {
  const { currentUser, restaurant } = useContext(RestaurantContext);

  const navigate = useNavigate();

  // need current restaurant id
  const restaurantId = restaurant[1].restaurantProfile.Id;

  const onFinish = ({
    name,
    description,
    categories,
    ingredients,
    price,
    image,
  }) => {
    // console.log(name, description, categories.split(" "), price, image);
    set(ref(db, `/restaurants/${restaurantId}/dishes/${uuidv4()}`), {
      userId: currentUser.uid,
      name,
      description,
      categories: categories.split(" "),
      ingredients: ingredients.split(" "),
      image,
      price,
    }).catch((err) => {
      console.log("err", err);
    });
    message.success("Dish has been created");
    navigate("/menu");
  };
  return (
    <Card title="New Menu Item" style={{ margin: 20 }}>
      <Form layout="vertical" wrapperCol={{ span: 8 }} onFinish={onFinish}>
        <Form.Item
          rules={[
            {
              required: true,
              message: "This field is required !",
            },
          ]}
          label="Dish Name"
          name="name"
          required
        >
          <Input placeholder="Enter dish name" />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: "This field is required !",
            },
          ]}
          label="Categories"
          name="categories"
          required
        >
          <Input placeholder="vegetarian indian ..." />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: "This field is required !",
            },
          ]}
          label="Ingredients"
          name="ingredients"
          required
        >
          <Input placeholder="chicken chili butter  ..." />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: "This field is required !",
            },
          ]}
          label="Dish Description"
          name="description"
        >
          <TextArea rows={4} placeholder="Enter dish description" />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: "This field is required !",
            },
          ]}
          label="Image Link"
          name="image"
          required
        >
          <Input placeholder="Enter dish image link" />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: "This field is required !",
            },
          ]}
          label="Price ($)"
          name="price"
          required
        >
          <InputNumber />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default CreateMenuItem;
