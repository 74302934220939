import React, { useState } from "react";
import { Form, Input, Card, Button, message } from "antd";
import { ref, set } from "firebase/database";
import { db } from "../../firebase";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";

const RestaurantUser = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");

  const navigate = useNavigate();

  const createNewRestaurantUser = async () => {
    let userProfile = {
      Id: uuidv4(),
      firstName: firstName,
      lastName: lastName,
      phone: phone,
    };

    console.log(userProfile);

    set(ref(db, `/restaurantsUsers/${userProfile.Id}`), {
      userProfile: userProfile,
      createdAt: new Date().toString(),
    }).catch((err) => {
      console.log("err", err);
    });
    // setRestaurant(userProfile);
    message.success("user has been created !");
    navigate("/");
  };

  return (
    <Card title="User Profile" style={{ margin: 20 }}>
      <Form
        layout="vertical"
        wrapperCol={{ span: 8 }}
        onFinish={createNewRestaurantUser}
      >
        <Form.Item label="First Name" required>
          <Input
            placeholder="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </Form.Item>

        <Form.Item label="Last name" required>
          <Input
            placeholder="last name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </Form.Item>

        <Form.Item label="Phone Number " required>
          <Input
            placeholder="phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default RestaurantUser;
