import { Card, Table, Button, Divider, message } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import { RestaurantContext } from "../../Contexts/RestaurantContext";
import { ref, remove } from "firebase/database";
import { db } from "../../firebase";

const RestaurantMenu = () => {
  const { currentUser, restaurant, dish, setDish } =
    useContext(RestaurantContext);

  // // fetch dishes for current restaurant
  const dishes = Object.entries(restaurant[1]?.dishes);

  const restaurantId = restaurant[0];

  const navigate = useNavigate();

  const renderNewItemButton = () => (
    <Link to={"create"}>
      <Button type="primary">New Item</Button>
    </Link>
  );

  // to delete a dish
  const deleteDish = (id) => {
    // delete dish by id
    remove(ref(db, `/restaurants/${restaurantId}/dishes/${id}`)).catch(
      (err) => {
        console.log("err", err);
      }
    );
    message.success("Dish has been Deleted");
  };

  // to update dish
  const updateDish = (dishObj) => {
    setDish(dishObj);
    navigate(`update`);
  };

  return (
    <Card title={"Menu"} style={{ margin: 20 }} extra={renderNewItemButton()}>
      {/* <Table dataSource={dishes} columns={tableColumns} rowKey="id" /> */}

      <table>
        <thead>
          <tr>
            <th style={{ width: "14rem" }}>Menu Item</th>
            <th style={{ width: "10rem" }}>Price</th>
            <th style={{ width: "10rem" }}>Delete</th>
            <th style={{ width: "10rem" }}>Update</th>
          </tr>
        </thead>
        <tbody>
          {dishes.map((dish) => (
            <tr key={dish[0]}>
              <td style={{ textAlign: "center" }}>{dish[1].name}</td>
              <td style={{ textAlign: "center" }}>{dish[1].price}</td>
              <td style={{ textAlign: "center" }}>
                {" "}
                <Button onClick={() => deleteDish(dish[0])} danger>
                  Remove
                </Button>
              </td>
              <td style={{ textAlign: "center" }}>
                <Button onClick={() => updateDish(dish)} type="primary">
                  Update
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Card>
  );
};

export default RestaurantMenu;
