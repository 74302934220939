import React from "react";
import { Form, Input, Button, Card, InputNumber, message } from "antd";
import { ref, update } from "firebase/database";
import { useContext } from "react";
import { RestaurantContext } from "../../Contexts/RestaurantContext";
import { db } from "../../firebase";
import { useNavigate } from "react-router-dom";

const { TextArea } = Input;

function UpdateMenu() {
  const { currentUser, restaurant, dish } = useContext(RestaurantContext);

  const navigate = useNavigate();

  //  current restaurant id
  const restaurantId = restaurant[1].restaurantProfile.Id;

  const onFinish = ({
    name,
    description,
    categories,
    ingredients,
    price,
    image,
  }) => {
    update(ref(db, `/restaurants/${restaurantId}/dishes/${dish[0]}`), {
      userId: currentUser.uid,
      name,
      description,
      categories: categories.split(" "),
      ingredients: ingredients.split(" "),
      image,
      price,
    }).catch((err) => {
      console.log("err", err);
    });
    message.success("Dish has been updated");
    navigate("/menu");
  };
  const initialValues = {
    name: dish[1].name,
    description: dish[1].description,
    categories: dish[1].categories
      ? Object.values(dish[1].categories).join(" ")
      : "",
    ingredients: dish[1].ingredients
      ? Object.values(dish[1].ingredients).join(" ")
      : "",
    image: dish[1].image,
    price: dish[1].price,
  };

  return (
    <Card title=" Update Dish Item" style={{ margin: 20 }}>
      <Form
        layout="vertical"
        wrapperCol={{ span: 8 }}
        initialValues={initialValues}
        onFinish={onFinish}
      >
        <Form.Item label="Dish Name" name="name" required>
          <Input />
        </Form.Item>
        <Form.Item label="Categories" name="categories" required>
          <Input />
        </Form.Item>
        <Form.Item label="Ingredients" name="ingredients" required>
          <Input />
        </Form.Item>
        <Form.Item label="Dish Description" name="description" required>
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item label="Image Link" name="image" required>
          <Input />
        </Form.Item>
        <Form.Item label="Price ($)" name="price" required>
          <InputNumber />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Update
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
}

export default UpdateMenu;
