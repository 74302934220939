import { Card, Table, Tag } from "antd";
//import ordersHistory from "../../assets/data/orders-history.json";
//firebase
import { useEffect, useState } from "react";
import { db } from "../../firebase";
import { ref, onValue, get } from "firebase/database";
import { useNavigate } from "react-router-dom";
// ab ki hua
const OrderHistory = () => {
  const [orders, setOrders] = useState([]);

  // get order from firebase // orders_zones
  const ordersRef = ref(db, "order_Zones");
  useEffect(() => {
    onValue(ordersRef, (snapshot) => {
      const data = snapshot.exportVal();

      setOrders(Object.entries(data));
    });
  }, []);
  const navigate = useNavigate();

  // to get time passed since the order was placed
  function getTimeAgo(dateString) {
    const now = new Date();
    const date = new Date(dateString);
    const diff = now - date;

    const msPerMinute = 60 * 1000;
    const msPerHour = msPerMinute * 60;
    const msPerDay = msPerHour * 24;
    const msPerMonth = msPerDay * 30;
    const msPerYear = msPerDay * 365;

    if (diff < msPerMinute) {
      return Math.round(diff / 1000) + " seconds ago";
    } else if (diff < msPerHour) {
      return Math.round(diff / msPerMinute) + " minutes ago";
    } else if (diff < msPerDay) {
      return Math.round(diff / msPerHour) + " hours ago";
    } else if (diff < msPerMonth) {
      return Math.round(diff / msPerDay) + " days ago";
    } else if (diff < msPerYear) {
      return Math.round(diff / msPerMonth) + " months ago";
    } else {
      return Math.round(diff / msPerYear) + " years ago";
    }
  }

  // to get the status
  function getStatus(obj) {
    for (const key in obj) {
      if (obj[key] === true) {
        return key;
      }
    }
  }

  // function to map over the orders_zones object recived from firbase and access all the nest orders in each zone
  function getAllOrders(ordersObject) {
    let result = [];
    ordersObject.map((ord) => {
      Object.entries(ord[1]).map((ord) => {
        return result.push(ord[1]);
      });
    });
    return result;
  }

  let arrayOfAllOrders = getAllOrders(orders);

  // display only orders with status == declined and prepared
  const filteredOrders = arrayOfAllOrders.filter(
    (ord) => ord.status.prepared === true || ord.status.declinedByRest === true
  );

  return (
    <Card title={"Orders"} style={{ margin: 20 }}>
      <table>
        <thead>
          <tr>
            <th style={{ width: "14rem" }}>Order ID</th>
            <th style={{ width: "10rem" }}>Created At</th>
            <th style={{ width: "10rem" }}>Price</th>
            <th style={{ width: "10rem" }}>Status</th>
          </tr>
        </thead>
        <tbody>
          {filteredOrders.map((order) => (
            <tr key={order.ID}>
              <td>{order.ID}</td>
              <td style={{ textAlign: "center" }}>
                {getTimeAgo(order.createdAt)}
              </td>

              <td style={{ textAlign: "center" }}>{order.items[0].price} </td>
              <td
                style={{ textAlign: "center", cursor: "pointer" }}
                onClick={() => navigate(`/order/${order[0]}`)}
              >
                {getStatus(order.status)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Card>
  );
};

export default OrderHistory;
