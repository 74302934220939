import DetailedOrder from "../../modules/DetailedOrder";
import Orders from "../../modules/Orders";
import RestaurantMenu from "../../modules/RestaurantMenu";
import CreateMenuItem from "../../modules/CreateMenuItem";
import OrderHistory from "../../modules/OrderHisotry";
import Settings from "../../modules/Settings";
import Login from "../../modules/LoginForm";
import Registration from "../../modules/RegistrationForm";
import RestaurantUser from "../../modules/RestaurantUser";
import { Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "../../modules/Dashboard";
import UpdateMenu from "../../modules/UpdateMenuItem";

import { useContext } from "react";
import { RestaurantContext } from "../../Contexts/RestaurantContext";

const AppRoutes = () => {
  const { currentUser } = useContext(RestaurantContext);

  const RequireAuth = ({ children }) => {
    return currentUser ? children : <Navigate to="/login" />;
  };

  return (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route path="registration" element={<Registration />} />
      <Route
        path="dashboard"
        element={
          <RequireAuth>
            {" "}
            <Dashboard />
          </RequireAuth>
        }
      />
      <Route
        path="/"
        element={
          <RequireAuth>
            <Orders />
          </RequireAuth>
        }
      />
      <Route
        path="order/:zone"
        element={
          <RequireAuth>
            {" "}
            <DetailedOrder />
          </RequireAuth>
        }
      />
      <Route
        path="menu"
        element={
          <RequireAuth>
            <RestaurantMenu />{" "}
          </RequireAuth>
        }
      />
      <Route
        path="menu/update"
        element={
          <RequireAuth>
            <UpdateMenu />
          </RequireAuth>
        }
      />
      <Route
        path="menu/create"
        element={
          <RequireAuth>
            <CreateMenuItem />
          </RequireAuth>
        }
      />
      <Route
        path="order-history"
        element={
          <RequireAuth>
            <OrderHistory />
          </RequireAuth>
        }
      />
      <Route
        path="settings"
        element={
          <RequireAuth>
            <Settings />
          </RequireAuth>
        }
      />
      <Route
        path="restaurantUser"
        element={
          <RequireAuth>
            <RestaurantUser />
          </RequireAuth>
        }
      />
    </Routes>
  );
};

export default AppRoutes;
