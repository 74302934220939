import { useContext, useState } from "react";

import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { Button, Card, Form, Input } from "antd";
import { RestaurantContext } from "../../Contexts/RestaurantContext";

const Login = () => {
  /// context

  const { dispatch } = useContext(RestaurantContext);

  const [error, setError] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navitage = useNavigate();

  const handleLogin = (e) => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        dispatch({ type: "LOGIN", payload: user });
        // console.log(user);
        navitage("/");
      })
      .catch((error) => {
        setError(true);
        console.log("err", error);
      });
  };

  return (
    <Card title="Login" style={{ margin: 20 }}>
      <Form layout="vertical" wrapperCol={{ span: 8 }} onFinish={handleLogin}>
        <Form.Item label="Email  " required>
          <Input
            placeholder="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Item>
        <Form.Item label="Password" required>
          <Input.Password
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Login
          </Button>
        </Form.Item>
        {error && (
          <span style={{ color: "red" }}>Wrong email or password!</span>
        )}
        <span> demo user email : rest01@gmail.com || pass : 123456</span>
      </Form>
    </Card>
  );
};

export default Login;
