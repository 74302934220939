import { Menu } from "antd";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { RestaurantContext } from "../../Contexts/RestaurantContext";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase";

const SideMenu = () => {
  const navigate = useNavigate();
  const { dispatch, currentUser, restaurant } = useContext(RestaurantContext);

  let restaurantName = restaurant ? restaurant[1]?.restaurantProfile.name : "";

  const logOut = async () => {
    await signOut(auth);
    dispatch({ type: "LOGOUT" });
    // console.log(currentUser);
    navigate("/login");
  };
  // non logged in users side menu
  const menuItemsGuest = [
    {
      key: "login",
      label: "Login",
    },
    {
      key: "registration",
      label: "Register",
    },
  ];

  // side menu for logged in users
  const menuItems = [
    {
      key: "/",
      label: "Orders",
    },
    // {
    //   key: "dashboard",
    //   label: "Dashboard",
    // },
    {
      key: "menu",
      label: "Menu",
    },
    {
      key: "order-history",
      label: "Order History",
    },
    {
      key: "settings",
      label: `Settings - ${restaurantName}`,
    },
    {
      key: "restaurantUser",
      label: "User Profile",
    },

    {
      key: "signOut",
      label: "Sign out",
      danger: "true",
    },
  ];

  return (
    <>
      <Menu
        items={currentUser ? menuItems : menuItemsGuest}
        onClick={(menuItem) =>
          menuItem.key === "signOut" ? logOut() : navigate(menuItem.key)
        }
      />
    </>
  );
};

export default SideMenu;
